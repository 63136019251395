#background-moldalAddPostIt {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--text-btn-transparent);
  z-index: 2;
}

#container-moldalAddPostIt {
  width: 30%;
  min-height: 40vh;
  background-color: var(--white);
  border-radius: 7px
}

#container-icon-moldalAddPostIt {
  width: 100%;
  text-align: end;
  padding: 3%;
}

#form-modalAddPostIt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
  gap: 20px;
}

.div-content-moldalAddPostIt {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  font-size: 14px;
  font-weight: 700;
}

#textarea-modalAddPostIt {
  font-size: 14px;
  height: 15vh;
}

#btn-modalAddPostIt {
  border: 2px solid var(--border-btn);
  background-color: var(--postit-color);
  color: var(--black);
  font-weight: 700;
  padding: 3%;
  width: 50%;
  border-radius: 5px;
  scale: 0.9;
  transition: all 0.3s ease;
  margin-bottom: 8%;
}

#btn-modalAddPostIt:hover {
  cursor: pointer;
  scale: 1;
}

#icon-moldalAddPostIt {
  cursor: pointer;
}