#canva-container-main {
  min-width: 100%;
  min-height: 100vh;
}

#canva-container-top {
  min-height: 70vh;
  display: flex;
  min-width: 100%;
}

#canva-container-bottom {
  min-height: 30vh;
  display: flex;
  min-width: 100%;
}

#button-canva {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 2px solid var(--border-btn-transparent);
  background-color: var(--btn-transparent-color);
  color: var(--text-btn-transparent);
  font-weight: 700;
  padding: 1% 1.5%;
  transition: all 0.3s ease;
}

#button-canva:hover {
  border: 2px solid var(--border-btn);
  background-color: var(--postit-color);
  color: var(--black);
  cursor: pointer;
}