#container-popUp {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 5;
  background-color: var(--border-btn-transparent);
  display: flex;
  justify-content: center;
  align-items: center;
}

#div-popUpBox {
  min-width: 16%;
  min-height: 22%;
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5%;
  gap: 24px;
}

#btn-confirmPopUp {
  padding: 4% 10%;
  background-color: var(--black);
  border-radius: 5px;
  color: var(--postit-color);
  font-weight: bold;
  cursor: pointer;
  border: 1px solid var(--black);
}

#btn-confirmPopUp:hover {
  background-color: var(--postit-color);
  color: var(--black);
}

#div-popUp-btn {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#p-pupUp {
  text-align: center;
  width: 90%;
  padding: 3% 0;
  border-bottom: 1px solid var(--border-btn-transparent);
}