.canva-area {
  border: 1.5px solid var(--black);
  width: 50%;
}

.title-canva-area {
  font-size: 16px;
  padding: 5%;
}

.text-canva-area {
  font-size: 12px;
  font-weight: 300;
  color: var(--light-text);
  padding: 0 5%;
}