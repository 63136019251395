#postIt-main-container {
  width: 80%;
}

#postIt-container {
  width: 100%;
  background-color: var(--postit-color);
  border: 1.5px solid var(--black);
  padding: 2%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Adiciona transição suave para a mudança de cor de fundo */
}

#postIt-container:hover {
  background-color: var(--postit-hover);
}

#postIt-content {
  font-size: 12px;
}

#btn-delete-postIt {
  margin-bottom: 1%;
  border-radius: 0;
  border: 1px solid var(--black);
  border-top: none;
  background-color: var(--red);
  width: 100%;
  padding: 1%;
  opacity: 0;
  transition: opacity 0.3s ease;
  /* Adiciona transição suave para a mudança de opacidade */
}

#btn-delete-postIt.visible {
  opacity: 1;
}

#btn-delete-postIt.hidden {
  opacity: 0;
}

#btn-delete-postIt:hover {
  background-color: var(--dark-red);
  cursor: pointer;
}

#canva-container-bottom div div #postIt-main-container {
  width: 27%;
}