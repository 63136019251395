* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  --light-text: #222222;
  --border-btn: #424242;
  --postit-color: #FFFF99;
  --postit-hover: #02cf02;
  --btn-transparent-color: rgba(255, 255, 153, 0.4);
  --border-btn-transparent: rgba(66, 66, 66, 0.4);
  --text-btn-transparent: rgba(0, 0, 0, 0.4);
  --black: #000000;
  --white: #FFFFFF;
  --red: #FF0000;
  --dark-red: #b30000;
}

@media print {
  .text-canva-area{
    display: none;
  }
}